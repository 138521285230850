import React from "react";

const RotateIcon = ({ fill }: { fill: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Arrow / Arrow_Sub_Right_Up">
      <path
        id="Vector"
        d="M11 11.2142L16 6.21417M16 6.21417L21 11.2142M16 6.21417V14.0173C16 15.1352 16 15.6944 15.7822 16.1218C15.5905 16.4981 15.2839 16.8047 14.9076 16.9964C14.4802 17.2142 13.921 17.2142 12.8031 17.2142H3"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default RotateIcon;
