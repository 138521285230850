import React, { FC, useLayoutEffect, useState } from "react";
import { useAppDispatch } from "store";
import { setUploadPhoto } from "store/slices/photos";
import classNames from "classnames";
import styles from "./ReadyComponent.module.scss";
import UploadIcon from "assets/svg/download.svg";
import CameraIcon from "assets/svg/camera.svg";
import LogoIcon from "assets/svg/white-union.svg";
import BackIcon from "assets/svg/back-icon.svg";
import { navigationComponents } from "constants/navigation";
import { getStudioData } from "helpers/sessionStorageHelpers";
import heic2any from "heic2any";
import InformationModal from "components/modals/InformationModal/InformationModal";

type ReadyComponentProps = {
  setActiveComponent: (val: string) => void;
};

const ReadyComponent: FC<ReadyComponentProps> = ({ setActiveComponent }) => {
  const dispatch = useAppDispatch();
  const [isAllowPhotoUpload, setIsAllowPhotoUpload] = useState(false);
  const [infoModalIsOpen, toggleInformationModal] = useState(false);

  useLayoutEffect(() => {
    const studioData = getStudioData();
    if (studioData) {
      const dataObj = JSON.parse(studioData);
      setIsAllowPhotoUpload(dataObj.photoConfiguration.isAllowPhotoUpload);
    }
  }, []);
  const onClickCamera = () =>
    setActiveComponent(navigationComponents.takePhoto);

  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const MAX_IMAGE_SIZE = 15 * 1024 * 1024;

      const blob: Blob = file;
      const fileB: File = file;

      const reader = new FileReader();

      if (file.size > MAX_IMAGE_SIZE) {
        event.target.value = "";
        toggleInformationModal(true);
      } else if (/image\/hei(c|f)/.test(fileB.type)) {
        heic2any({ blob, toType: "image/jpeg", quality: 1 })
          .then((jpgBlob) => {
            reader.onloadend = () => {
              const base64String = reader.result;
              dispatch(
                setUploadPhoto({
                  uploadPhoto: base64String,
                  photoFrom: "upload",
                }),
              );
              setActiveComponent(navigationComponents.adjustYourPhoto);
            };
            if (jpgBlob) {
              reader.readAsDataURL(jpgBlob as Blob);
            }
          })
          .catch((err) => {
            console.log("eeee", err);
            //Handle error
          });
      } else {
        reader.onloadend = () => {
          const base64String = reader.result;
          dispatch(
            setUploadPhoto({ uploadPhoto: base64String, photoFrom: "upload" }),
          );
          setActiveComponent(navigationComponents.adjustYourPhoto);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const navigateToExamples = () =>
    setActiveComponent(navigationComponents.examples);

  return (
    <>
      <div
        className={classNames(
          "full-width flex a-items-c flex-column",
          styles.container,
          styles.borderRadius,
        )}
      >
        <div
          className={classNames("text-color-light t-align-c", styles.header)}
        >
          Ready for your
          <br /> headshot?
        </div>
        <div
          className={classNames(
            "flex justify-content-start",
            styles.btnsContainer,
          )}
        >
          {isAllowPhotoUpload && (
            <div
              className={classNames(
                "dark-bg-btn flex a-items-c gap8",
                styles.customBtn,
              )}
            >
              <label
                htmlFor={"formId"}
                className="full-width flex justify-content-start i-align-c gap8"
              >
                <img src={UploadIcon} alt={""} />
                <p>Upload</p>
              </label>
              <input
                id="formId"
                type="file"
                accept=".png, .jpg, .jpeg, .heic"
                name="myImage"
                style={{ display: "none" }}
                onChange={handleUpload}
              />
            </div>
          )}
          <div
            className={classNames(
              "gold-bg-btn flex a-items-c gap8",
              styles.customBtn,
            )}
            onClick={onClickCamera}
          >
            <img src={CameraIcon} alt="" />
            Camera
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              styles.footerLeftWrapper,
              "flex a-items-c full-width space-between",
            )}
          >
            <p className="text-white qr-footer-text">
              © 2024. Remote is a <span className="text-gold">Studio Pod</span>{" "}
              Product.
            </p>
            <img src={LogoIcon} alt="" />
          </div>
          <div
            className={classNames(styles.defaultBtnWrapper, styles.btn)}
            onClick={navigateToExamples}
          >
            <div className={classNames(styles.defaultBtn, styles.grayBtn)}>
              <img src={BackIcon} alt="" />
              Back
            </div>
          </div>
        </div>
      </div>
      <InformationModal
        modalIsOpen={infoModalIsOpen}
        setIsOpen={toggleInformationModal}
        message={"Maximum file size of 15 MB for images"}
      />
    </>
  );
};

export default ReadyComponent;
