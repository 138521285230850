import React, { FC, useEffect } from "react";
import styles from "./IntroComponent.module.scss";
import Logo from "assets/svg/full-logo.svg";
import classNames from "classnames";
import DefaultButton from "components/buttons/DefaultButton/DefaultButton";
import Footer from "components/Footer/Footer";
import DefaultModal from "components/modals/DefaultModal";
import { navigationComponents } from "constants/navigation";
import { jwtDecode } from "jwt-decode";
import {
  getUserRole,
  setUserRole,
} from "../../../../helpers/sessionStorageHelpers";

type IntroComponentProps = {
  setActiveComponent: (val: string) => void;
};
const IntroComponent: FC<IntroComponentProps> = ({ setActiveComponent }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const navigateToSetUserData = () => {
    const userRole = getUserRole();
    if (userRole === "user") {
      setActiveComponent(navigationComponents.userData);
    } else {
      setActiveComponent(navigationComponents.headshotTips);
    }
  };

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      const decoded: any = jwtDecode(token);
      if (
        decoded["http://schemas.studiopod.com/claims/invitetype"] === "Admin"
      ) {
        setUserRole("admin");
      } else {
        setUserRole("user");
      }
    }
  });

  return (
    <>
      <div
        className={classNames(
          styles.bgContainer,
          "full-width flex a-items-c intro-bg",
        )}
      >
        <img src={Logo} alt="" className="logo-width" />
      </div>
      <DefaultButton
        onClick={() => setIsOpen(true)}
        text="Start your Studio Pod experience"
        customStyles="intro-btn"
      />
      <Footer customStyles="intro-footer" />
      <DefaultModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        navigateToQRCode={navigateToSetUserData}
      />
    </>
  );
};

export default IntroComponent;
