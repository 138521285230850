import React, { FC, useLayoutEffect, useState } from "react";
import classNames from "classnames";
import styles from "./QrCodeComponent.module.scss";
import UploadIcon from "assets/svg/download.svg";
import Footer from "./Footer/Footer";
import { navigationComponents } from "constants/navigation";
import { setUploadPhoto } from "store/slices/photos";
import { useAppDispatch } from "store";
import QrCodeGenerator from "components/QrCode/QrCode";
import { getStudioData } from "helpers/sessionStorageHelpers";
import InformationModal from "../../../../components/modals/InformationModal/InformationModal";
import heic2any from "heic2any";

type QrCodeComponentProps = {
  setActiveComponent: (val: string) => void;
};
const QrCodeComponent: FC<QrCodeComponentProps> = ({ setActiveComponent }) => {
  const dispatch = useAppDispatch();
  const url = window.location.href;
  const [isAllowPhotoUpload, setIsAllowPhotoUpload] = useState(false);
  const [infoModalIsOpen, toggleInformationModal] = useState(false);

  const onClickContinue = () =>
    setActiveComponent(navigationComponents.headshotTips);

  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const MAX_IMAGE_SIZE = 15 * 1024 * 1024;

      const blob: Blob = file;
      const fileB: File = file;

      const reader = new FileReader();

      if (file.size > MAX_IMAGE_SIZE) {
        event.target.value = "";
        toggleInformationModal(true);
        event.target = null;
      } else if (/image\/hei(c|f)/.test(fileB.type)) {
        heic2any({ blob, toType: "image/jpeg", quality: 1 })
          .then((jpgBlob) => {
            reader.onloadend = () => {
              const base64String = reader.result;
              dispatch(
                setUploadPhoto({
                  uploadPhoto: base64String,
                  photoFrom: "upload",
                }),
              );
              setActiveComponent(navigationComponents.adjustYourPhoto);
            };
            if (jpgBlob) {
              reader.readAsDataURL(jpgBlob as Blob);
            }
          })
          .catch((err) => {
            console.log("eeee", err);
            //Handle error
          });
      } else {
        reader.onloadend = () => {
          const base64String = reader.result;
          dispatch(
            setUploadPhoto({ uploadPhoto: base64String, photoFrom: "upload" }),
          );
          setActiveComponent(navigationComponents.adjustYourPhoto);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  };

  useLayoutEffect(() => {
    const studioData = getStudioData();
    if (studioData) {
      const dataObj = JSON.parse(studioData);
      setIsAllowPhotoUpload(dataObj.photoConfiguration.isAllowPhotoUpload);
    }
  }, []);

  return (
    <>
      <div
        className={classNames("full-width flex a-items-c", styles.container)}
      >
        <div className={styles.leftContainer}>
          <div className={styles.textContainer}>
            <p className={styles.header}>Grab your phone and get ready</p>
            <p className={styles.subscription}>
              To get started, scan the QR code with your phone&apos;s camera. If
              you&apos;d like to upload a high-quality photo instead, continue
              anyway.
            </p>
          </div>
          <div
            className={classNames(
              "flex justify-content-start",
              styles.btnsContainer,
            )}
          >
            <div
              className={classNames("gold-bg-btn", styles.customBtn)}
              onClick={onClickContinue}
            >
              Continue
            </div>
            {isAllowPhotoUpload && (
              <div
                className={classNames(
                  "dark-bg-btn flex a-items-c gap8",
                  styles.customBtn,
                )}
              >
                <label
                  htmlFor={"formId1"}
                  className="full-width flex justify-content-start i-align-c gap8"
                >
                  <img src={UploadIcon} alt={""} />
                  <p>Upload</p>
                </label>
                <input
                  id="formId1"
                  type="file"
                  accept=".png, .jpg, .jpeg, .heic"
                  name="myImage"
                  style={{ display: "none" }}
                  onChange={handleUpload}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(
            "t-align-c flex flex-column a-items-c",
            styles.rightContainer,
          )}
        >
          <QrCodeGenerator link={url} />
        </div>
      </div>
      <Footer customStyles="intro-footer" />
      <InformationModal
        modalIsOpen={infoModalIsOpen}
        setIsOpen={toggleInformationModal}
        message={"Maximum file size of 15 MB for images"}
      />
    </>
  );
};

export default QrCodeComponent;
