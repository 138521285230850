import React, { useEffect, useState } from "react";
import Layout from "components/Layout";
import { navigationComponents } from "constants/navigation";
import HeadshotTipsComponent from "./components/HeadshotTipsComponent/HeadshotTipsComponent";
import QrCodeComponent from "./components/QrCodeComponent/QrCodeComponent";
import ExamplesComponent from "./components/ExamplesComponent/ExamplesComponent";
import ReadyComponent from "./components/ReadyComponent/ReadyComponent";
import AdjustPhotoComponent from "./components/AdjustPhotoComponent/AdjustPhotoComponent";
import TakePhotoComponent from "./components/TakePhotoComponent/TakePhotoComponent";
import RemovingBgComponent from "./components/RemovingBgComponent/RemovingBgComponent";
import ConfirmSubmissionComponent from "./components/ConfirmSubmissionComponent/ConfirmSubmissionComponent";
import IndividualHeadshotsComponent from "./components/IndividualHeadshotsComponent/IndividualHeadshotsComponent";
import EndSessionPageComponent from "./components/EndSessinComponent/EndSessinComponent";
import { isMobileOnly } from "react-device-detect";
import http from "helpers/http";
import {
  clearSessionStorage,
  getUserRole,
  setStudioData,
  setToken,
  setUserData,
} from "helpers/sessionStorageHelpers";
import UserDataComponent from "./components/UserDataComponent/UserDataComponent";
import GuidelinesComponent from "./components/GuidelinesComponent/GuidelinesComponent";
import LoadingAnimation from "components/animations/Loading/Loading";
import InformationModal from "components/modals/InformationModal/InformationModal";
import IntroComponent from "./components/IntroComponent/IntroComponent";

const Session = () => {
  const [activeComponent, setActiveComponent] = useState(
    navigationComponents.intro,
  );
  const [isLoading, setLoading] = useState(false);
  const [isInfoModalOpen, toggleInfoModal] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const [infoModalIsOpen, toggleInformationModal] = useState(false);
  const token = searchParams.get("token");
  const userRole = getUserRole();

  const fetchUserData = async () => {
    if (token) {
      try {
        const res: any = await http.get(
          "/Session/contact-information-and-requirements-studio/",
          { headers: { Authorization: `Bearer ${token}` } },
        );
        if (res?.responseStatus?.code === 1061) {
          toggleInfoModal(true);
        } else {
          if (!isMobileOnly) {
            if (res.data.studio.photoReviewAndAccess.isSuggestMobileDevice) {
              toggleInformationModal(true);
            }
          }
          const studioData = JSON.stringify(res.data.studio);
          const data = JSON.stringify(res.data.contact);
          setUserData(data);
          setStudioData(studioData);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    clearSessionStorage();
    if (token) {
      setToken(token);
      fetchUserData().catch();
    } else {
      toggleInfoModal(true);
    }
  }, []);

  const onClickOkBtn = () => {
    toggleInfoModal(false);
    setActiveComponent(navigationComponents.endSession);
  };

  return (
    <Layout activeComponent={activeComponent}>
      <>
        {activeComponent === navigationComponents.intro && (
          <IntroComponent setActiveComponent={setActiveComponent} />
        )}
        {activeComponent === navigationComponents.userData &&
          userRole === "user" && (
            <UserDataComponent setActiveComponent={setActiveComponent} />
          )}
        {activeComponent === navigationComponents.headshotTips && (
          <HeadshotTipsComponent setActiveComponent={setActiveComponent} />
        )}
        {activeComponent === navigationComponents.guidelines && (
          <GuidelinesComponent setActiveComponent={setActiveComponent} />
        )}
        {activeComponent === navigationComponents.qrCode && (
          <QrCodeComponent setActiveComponent={setActiveComponent} />
        )}
        {activeComponent === navigationComponents.examples && (
          <ExamplesComponent setActiveComponent={setActiveComponent} />
        )}
        {activeComponent === navigationComponents.ready && (
          <ReadyComponent setActiveComponent={setActiveComponent} />
        )}
        {activeComponent === navigationComponents.adjustYourPhoto && (
          <AdjustPhotoComponent setActiveComponent={setActiveComponent} />
        )}
        {activeComponent === navigationComponents.takePhoto && (
          <TakePhotoComponent setActiveComponent={setActiveComponent} />
        )}
        {activeComponent === navigationComponents.removingBackground && (
          <RemovingBgComponent
            setActiveComponent={setActiveComponent}
            setLoading={setLoading}
          />
        )}
        {activeComponent === navigationComponents.confirmSubmission && (
          <ConfirmSubmissionComponent
            setActiveComponent={setActiveComponent}
            setLoading={setLoading}
          />
        )}
        {activeComponent === navigationComponents.individualHeadshots && (
          <IndividualHeadshotsComponent
            setActiveComponent={setActiveComponent}
          />
        )}
        {activeComponent === navigationComponents.endSession && (
          <EndSessionPageComponent />
        )}
        {isLoading && <LoadingAnimation />}
        <InformationModal
          modalIsOpen={isInfoModalOpen}
          setIsOpen={onClickOkBtn}
          message={"This invite has previously been revoked or used."}
        />
        <InformationModal
          modalIsOpen={infoModalIsOpen}
          setIsOpen={toggleInformationModal}
          message={
            "To finish the session better use mobile phone or smartphone"
          }
        />
      </>
    </Layout>
  );
};

export default Session;
