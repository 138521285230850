import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./AdjustPhotoComponent.module.scss";
import NextIcon from "assets/svg/next-icon.svg";
import BackIcon from "assets/svg/back-icon.svg";
import * as faceapi from "face-api.js";
import "browser-fs-access";
import InformationModal from "components/modals/InformationModal/InformationModal";
import { useAppDispatch, useAppSelector } from "store";
import { setUploadPhoto } from "store/slices/photos";
import { isMobileOnly } from "react-device-detect";
import UploadIcon from "assets/svg/download.svg";
import AvatarEditor from "react-avatar-editor";
import ZoomIcon from "assets/svg/zoom";
import RotateIcon from "assets/svg/rotate";
import MinusEditIcon from "assets/svg/minus_circle_outline.svg";
import PlusEditIcon from "assets/svg/plus_circle_outline.svg";
import useWindowWidth from "hooks/useWindowsWidth";
import ProcessingAnimation from "components/animations/Processing/Processing";
import { navigationComponents } from "constants/navigation";
import { getStudioData } from "helpers/sessionStorageHelpers";

type AdjustPhotoComponentProps = {
  setActiveComponent: (val: string) => void;
};

const AdjustPhotoComponent: FC<AdjustPhotoComponentProps> = ({
  setActiveComponent,
}) => {
  const dispatch = useAppDispatch();
  const windowWidth = useWindowWidth();

  const { uploadPhoto, photoFrom, facingMode } = useAppSelector(
    (state) => state.photos,
  );

  const [isOpenInfoModal, toggleInfoModal] = useState(false);

  const [facesDetected, setFacesDetected] = useState([]);
  const [isProcessing, setProcessing] = useState(true);
  const [imageScale, setImageScale] = useState(1);
  const [imageRotate, setImageRotate] = useState(0);
  const [adjustMode, setAdjustMode] = useState("zoom");
  const [settings, setSettings] = useState({ min: 1, max: 2, step: 0.01 });
  const imageRef = useRef(null);
  const [frameId, setFrameId] = useState(1);

  useLayoutEffect(() => {
    const studioData = getStudioData();
    if (studioData) {
      const dataObj = JSON.parse(studioData);
      setFrameId(dataObj.photoConfiguration.poseProfile.id);
    }
  }, []);

  const customStyles =
    facingMode === "user" && photoFrom === "camera"
      ? { transform: "scaleX(-1)" }
      : undefined;

  const editorRef = useRef<AvatarEditor>(null);

  const detectFaces = async () => {
    if (imageRef.current && !facesDetected.length) {
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      const detections: any = await faceapi.detectAllFaces(
        imageRef.current,
        new faceapi.TinyFaceDetectorOptions(),
      );
      setFacesDetected(detections);
      if (!detections.length) {
        toggleInfoModal(true);
      }
    }
  };

  useEffect(() => {
    if (uploadPhoto.split(";")[0].trim().split("/")[1] !== "heic") {
      detectFaces().catch();
      setProcessing(false);
    }
    setProcessing(false);
  }, [uploadPhoto]);

  const goToReadyPage = () => {
    dispatch(setUploadPhoto({ uploadPhoto: "", photoFrom: "" }));
    setActiveComponent(navigationComponents.ready);
  };

  const makePhotoAgain = () => {
    toggleInfoModal(false);
    dispatch(setUploadPhoto({ uploadPhoto: "", photoFrom: "" }));
    if (photoFrom === "camera") {
      setActiveComponent(navigationComponents.takePhoto);
    } else {
      setActiveComponent(navigationComponents.ready);
    }
  };

  const onChangeImageScale = (e: any) => {
    if (adjustMode === "zoom") {
      setImageScale(e.target.value);
    } else {
      setImageRotate(e.target.value);
    }
  };

  const onChooseZoomMode = () => {
    setAdjustMode("zoom");
    setSettings({ min: 1, max: 2, step: 0.01 });
  };

  const onChooseRotateMode = () => {
    setAdjustMode("rotate");
    setSettings({ min: -45, max: 45, step: 2 });
  };

  const chooseAnotherPhoto = () => {
    if (photoFrom === "camera") {
      setActiveComponent(navigationComponents.takePhoto);
    } else {
      setActiveComponent(navigationComponents.ready);
    }
    dispatch(setUploadPhoto({ uploadPhoto: "", photoFrom: "" }));
  };

  const onResetAdjust = () => {
    setImageScale(1);
    setImageRotate(0);
  };

  const onClickPlus = () => {
    if (adjustMode === "zoom") {
      if (+imageScale <= 2) {
        setImageScale((prev) => +prev + 0.01);
      }
    } else {
      if (+imageRotate <= 45) {
        setImageRotate((prev) => +prev + 1);
      }
    }
  };

  const onClickMinus = () => {
    if (adjustMode === "zoom") {
      if (+imageScale >= 1) {
        setImageScale((prev) => +prev - 0.01);
      }
    } else {
      if (+imageRotate >= -45) {
        setImageRotate((prev) => +prev - 1);
      }
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      const image = new Image();
      image.src = "/assets/images/frames/shoulder_height.png"; // Path to the image
      image.onload = () => {
        const canvas = document.getElementsByTagName("canvas")[0];
        if (canvas) {
          const context = canvas.getContext("2d");
          if (context) {
            context.drawImage(image, 0, 0);
          }
        }
      };
    };

    loadImage().catch();
  }, []);

  const handleResize = (
    canvas: any,
    {
      cropperWidth,
      cropperHeight,
      scale = 1,
    }: { cropperWidth: number; cropperHeight: number; scale: number },
  ) => {
    const offScreenCanvas: HTMLCanvasElement | null = document.getElementById(
      "test-canvas",
    ) as HTMLCanvasElement;
    if (offScreenCanvas) {
      const width = cropperWidth * scale;
      const height = cropperHeight * scale;
      offScreenCanvas.width = width;
      offScreenCanvas.height = height;
      const context = offScreenCanvas.getContext("2d");

      if (context) {
        const image = new Image();
        image.src = canvas;

        image.onload = () => {
          context.drawImage(image, 0, 0, width, height);
          const res = offScreenCanvas.toDataURL("image/png", 0.9);
          dispatch(setUploadPhoto({ uploadPhoto: res, photoFrom: "" }));
          setActiveComponent(navigationComponents.removingBackground);
        };
      }
    }
  };

  const goToRemoveBg = () => {
    const img = editorRef.current?.getImage().toDataURL();
    handleResize(img, {
      cropperWidth: 2000,
      cropperHeight: 2000,
      scale: 1,
    });
  };

  return (
    <div
      className={classNames(
        "full-width full-height position-relative",
        styles.mainContainer,
      )}
    >
      <canvas
        style={{ position: "absolute", display: "none" }}
        id={"test-canvas"}
      />
      <div
        className={classNames(
          "full-width flex a-items-c flex-column",
          styles.container,
          styles.borderRadius,
        )}
      >
        <div
          className={classNames(
            "overflow-hidden position-relative flex a-items-c",
            styles.photoContainer,
          )}
        >
          <div
            style={{ width: "100%", height: "100%", display: "contents" }}
            className={"position-absolute"}
            role={"presentation"}
            id={"draggable"}
          >
            <AvatarEditor
              ref={editorRef}
              disableCanvasRotation={true}
              width={windowWidth < 768 ? 320 : 400}
              height={windowWidth < 768 ? 320 : 400}
              image={uploadPhoto}
              scale={+imageScale}
              rotate={+imageRotate}
              disableHiDPIScaling={false}
              style={customStyles}
            />
          </div>
          <img
            src={uploadPhoto}
            alt=""
            ref={imageRef}
            width={"100%"}
            style={{ display: "none" }}
          />
          <div className={styles.PoseOutline}>
            <img
              src={
                frameId === 1
                  ? require("assets/images/frames/no_outline.png")
                  : frameId === 2
                    ? require("assets/images/frames/shoulder_height.png")
                    : frameId === 3
                      ? require("assets/images/frames/shoulder_height_close.png")
                      : frameId === 4
                        ? require("assets/images/frames/shoulder_height_custom_examples.png")
                        : require("assets/images/frames/waist_height.png")
              }
              alt=""
            />
          </div>
        </div>
        <p className={classNames("text-gold", styles.goldText)}>
          Adjust your photo
        </p>
        <div
          className={classNames(
            "flex space-between a-items-c",
            styles.actionsContainer,
          )}
        >
          <div className="flex gap8">
            <div
              className={classNames(styles.smallBtn, {
                [styles.bgActive]: adjustMode === "zoom",
                [styles.bgDefault]: adjustMode !== "zoom",
              })}
              onClick={onChooseZoomMode}
            >
              <ZoomIcon fill={adjustMode === "zoom" ? "#1A1A1A" : "#C4C4BC"} />
              <p>Zoom</p>
            </div>
            <div
              className={classNames(styles.smallBtn, {
                [styles.bgActive]: adjustMode === "rotate",
                [styles.bgDefault]: adjustMode !== "rotate",
              })}
              onClick={onChooseRotateMode}
            >
              <RotateIcon
                fill={adjustMode === "rotate" ? "#1A1A1A" : "#C4C4BC"}
              />
              <p>Rotate</p>
            </div>
          </div>
          <div className={styles.textBtn} onClick={onResetAdjust}>
            Reset
          </div>
        </div>
        <div
          className={classNames(
            "flex a-items-c space-between gap8",
            styles.actionsContainer,
          )}
        >
          <img src={MinusEditIcon} alt="" onClick={onClickMinus} />
          <input
            name="scale"
            type="range"
            onChange={onChangeImageScale}
            min={settings.min}
            value={adjustMode === "zoom" ? imageScale : imageRotate}
            max={settings.max}
            step={settings.step}
          />
          <img src={PlusEditIcon} alt="" onClick={onClickPlus} />
        </div>
      </div>
      {isMobileOnly && (
        <div className={classNames(styles.mobileBtnWrapper, "flex")}>
          <div
            className={styles.getPhotoBtnWrapper}
            onClick={chooseAnotherPhoto}
          >
            <div
              className={classNames(
                styles.getPhotoBtn,
                "flex gap8 justify-content-start i-align-c",
              )}
            >
              <img src={UploadIcon} alt={""} />
              <p>{photoFrom === "upload" ? "Re Upload" : "Re Take Photo"}</p>
            </div>
          </div>
        </div>
      )}
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div className={classNames(styles.defaultBtnWrapper)}>
            <div
              onClick={goToReadyPage}
              className={classNames(styles.defaultBtn, styles.bgGray)}
            >
              <img src={BackIcon} alt="" />
              Back
            </div>
          </div>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              "flex a-items-c",
            )}
            onClick={chooseAnotherPhoto}
          >
            <div
              className={classNames(
                styles.getPhotoBtn,
                "flex gap8 justify-content-start i-align-c full-width",
              )}
            >
              <img src={UploadIcon} alt={""} />
              <p>{photoFrom === "upload" ? "Re Upload" : "Re Take Photo"}</p>
            </div>
          </div>
          <div
            className={classNames(styles.defaultBtnWrapper)}
            onClick={goToRemoveBg}
          >
            <div className={classNames(styles.defaultBtn, styles.bgGold)}>
              Continue
              <img src={NextIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <InformationModal
        message={
          photoFrom === "camera"
            ? "We couldn't find a face in your photo. Please take a picture again."
            : "We couldn't find a face in your uploaded photo. Please upload another photo."
        }
        modalIsOpen={isOpenInfoModal}
        setIsOpen={makePhotoAgain}
      />
      {isProcessing && <ProcessingAnimation />}
    </div>
  );
};

export default AdjustPhotoComponent;
