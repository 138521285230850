import React, { FC, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./TakePhotoComponent.module.scss";
import BackIcon from "assets/svg/back-icon.svg";
import "browser-fs-access";
import { useAppDispatch } from "store";
import { setUploadPhoto } from "store/slices/photos";
import LogoIcon from "assets/svg/white-union.svg";
import { isMobileOnly } from "react-device-detect";
import { navigationComponents } from "constants/navigation";
import { getStudioData } from "helpers/sessionStorageHelpers";
import { Camera, CameraType } from "react-camera-pro";
import { FacingMode } from "react-camera-pro/dist/components/Camera/types";

type TakePhotoComponentProps = {
  setActiveComponent: (val: string) => void;
};

const TakePhotoComponent: FC<TakePhotoComponentProps> = ({
  setActiveComponent,
}) => {
  const dispatch = useAppDispatch();
  const [frameId, setFrameId] = useState(1);
  const [facingMode, setFacingMode] = useState<FacingMode>("user");

  const camera = useRef<CameraType>(null);
  const goToReadyPage = () => {
    dispatch(setUploadPhoto({ uploadPhoto: "", photoFrom: "" }));
    setActiveComponent(navigationComponents.ready);
  };

  const switchCamera = () => {
    if (facingMode === "environment") {
      setFacingMode("user");
    } else {
      setFacingMode("environment");
    }
    if (camera.current) {
      // camera.current.switchCamera();
    }
  };
  const capture = () => {
    if (camera.current) {
      const photo = camera.current?.takePhoto();
      dispatch(
        setUploadPhoto({ uploadPhoto: photo, photoFrom: "camera", facingMode }),
      );
      setActiveComponent(navigationComponents.adjustYourPhoto);
    }
  };

  useLayoutEffect(() => {
    const studioData = getStudioData();
    if (studioData) {
      const dataObj = JSON.parse(studioData);
      setFrameId(dataObj.photoConfiguration.poseProfile.id);
    }
  }, []);

  return (
    <div
      className={classNames(
        "app-padding full-width full-height position-relative",
        styles.mainContainer,
      )}
    >
      <div
        className={classNames(
          "full-width flex a-items-c flex-column",
          styles.container,
          styles.borderRadius,
        )}
      >
        <div
          className={classNames(
            "position-relative flex",
            styles.cameraContainer,
          )}
          style={{
            overflow: "auto",
            background: "transparent",
            backdropFilter: "blur(3px)",
          }}
        >
          <canvas
            style={{ position: "absolute", display: "none" }}
            id={"test-canvas2"}
          />
          <Camera
            ref={camera}
            facingMode={facingMode}
            errorMessages={{
              noCameraAccessible:
                "No camera device accessible. Please connect your camera or try a different browser.",
              permissionDenied:
                "Permission denied. Please refresh and give camera permission.",
              switchCamera:
                "It is not possible to switch camera to different one because there is only one video device accessible.",
              canvas: "Canvas is not supported.",
            }}
          />
          <div className={styles.frameContainer}>
            <img
              src={
                frameId === 1
                  ? require("assets/images/frames/no_outline.png")
                  : frameId === 2
                    ? require("assets/images/frames/shoulder_height.png")
                    : frameId === 3
                      ? require("assets/images/frames/shoulder_height_close.png")
                      : frameId === 4
                        ? require("assets/images/frames/shoulder_height_custom_examples.png")
                        : require("assets/images/frames/waist_height.png")
              }
              alt=""
            />
          </div>
        </div>
        <>
          <div className={styles.getPhotoSmallBtnWrapper} onClick={capture}>
            Take Photo
          </div>
        </>
      </div>
      {isMobileOnly && (
        <div className={classNames(styles.mobileBtnWrapper, "flex")}>
          <div className={styles.getPhotoBtnWrapper} onClick={switchCamera}>
            <div className={styles.getPhotoBtn}>Switch Camera</div>
          </div>
          <div className={styles.getPhotoBtnWrapper} onClick={capture}>
            <div className={styles.getPhotoBtn}>Take Photo</div>
          </div>
        </div>
      )}
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div className={classNames(styles.defaultBtnWrapper)}>
            <div
              onClick={goToReadyPage}
              className={classNames(styles.defaultBtn, styles.bgGray)}
            >
              <img src={BackIcon} alt="" />
              Back
            </div>
          </div>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              "flex a-items-c",
            )}
          >
            <img src={LogoIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakePhotoComponent;
