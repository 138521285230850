export type User = {
  token: string;
  isUnlockAccount: boolean;
};
export const getToken = () => sessionStorage.getItem("token");
export const clearToken = () => sessionStorage.removeItem("token");

export const setToken = (data: string) => sessionStorage.setItem("token", data);

export const setUserData = (data: string) =>
  sessionStorage.setItem("userData", data);

export const setStudioData = (data: string) =>
  sessionStorage.setItem("studioData", data);

export const setImages = (data: string) =>
  sessionStorage.setItem("images", data);

export const getStudioData = () => sessionStorage.getItem("studioData");

export const getImages = () => sessionStorage.getItem("images");
export const getUserData = () => sessionStorage.getItem("userData");

export const setUserRole = (role: string) =>
  sessionStorage.setItem("userRole", role);
export const getUserRole = () => sessionStorage.getItem("userRole");

export const setUploadImage = (data: string) =>
  sessionStorage.setItem("uploadImage", data);

export const clearSessionStorage = () => sessionStorage.clear();
