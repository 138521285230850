import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import { navigationComponents } from "../constants/navigation";

type LayoutProps = {
  children: ReactNode;
  activeComponent?: string;
};

const Layout: FC<LayoutProps> = ({ children, activeComponent }) => {
  return (
    <div
      className={classNames("app-padding full-width full-height", {
        ["space-between flex flex-column"]:
          activeComponent === navigationComponents.headshotTips ||
          activeComponent === navigationComponents.examples ||
          activeComponent === navigationComponents.ready ||
          activeComponent === navigationComponents.userData ||
          activeComponent === navigationComponents.guidelines ||
          activeComponent === "public",
      })}
    >
      {children}
    </div>
  );
};

export default Layout;
