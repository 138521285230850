import React, { FC, useEffect, useState } from "react";
import styles from "./IndividualHeadshotsComponent.module.scss";
import classNames from "classnames";
import DownloadIcon from "assets/images/download";
import LogoIcon from "assets/svg/white-union.svg";
import NextIcon from "assets/svg/next-icon.svg";
import { navigationComponents } from "constants/navigation";
import { getImages } from "helpers/sessionStorageHelpers";

type IndividualHeadshotsComponentProps = {
  setActiveComponent: (val: string) => void;
};
const IndividualHeadshotsComponent: FC<IndividualHeadshotsComponentProps> = ({
  setActiveComponent,
}) => {
  const [imageData, setImageData] = useState<any[]>([]);

  useEffect(() => {
    const jsonImages = getImages();
    if (jsonImages) {
      const dataObj = JSON.parse(jsonImages);

      const images = [...dataObj];
      for (const image of images) {
        const imageElement = new Image();
        imageElement.src = image;
      }
      setImageData(images);
    }
  }, []);

  const [selectedImages, addImages] = useState([]);
  const onSelectImage = (el: string): void => {
    const newArr = [...selectedImages];
    const elIndex = newArr.findIndex((item) => item === el);
    if (elIndex !== -1) {
      newArr.splice(elIndex, 1);
    } else {
      newArr.push(el as never);
    }
    addImages(newArr);
  };

  async function downloadImage(image: string, imgFormat: string, i: number) {
    const response = await fetch(image);
    const blob = await response.blob();

    if (!blob) {
      console.error(`Image download failed for: ${image}`);
      return; // Skip to next image if download fails
    }

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `image-${i}.${imgFormat}`;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  const handleDownload = async () => {
    const downloadPromises = [];
    const imgFormat = imageData[0].originalImageUrl.split(".").pop();

    for (let i = 0; i < selectedImages.length; i++) {
      const image = selectedImages[i];
      downloadPromises.push(downloadImage(image, imgFormat, i));
    }

    try {
      // Wait for all download promises to resolve before continuing
      await Promise.all(downloadPromises);
      console.log("All images downloaded successfully!");
      setActiveComponent(navigationComponents.endSession);
    } catch (error) {
      console.error("Error downloading images:", error);
    }
  };

  const onPressEndSession = () =>
    setActiveComponent(navigationComponents.endSession);

  const navigateToDownloading = async () => {
    await handleDownload();
  };

  return (
    <div className={classNames("full-width full-height", styles.mainContainer)}>
      <div
        className={classNames(
          "full-width flex a-items-c",
          styles.mobileContainer,
        )}
      >
        <div className="flex flex-column a-items-c full-height space-between">
          <div className={styles.textContainer}>
            <p className={styles.header}>Individual headshots</p>
            <p className={styles.subscription}>
              Select a headshot to download. You can also revisit this page at
              any time via the emailed link.
            </p>
          </div>
          <div
            className={classNames("t-align-c", styles.mobileImagesContainer)}
          >
            <div className="flex gap8">
              <div className={styles.gridTemplate}>
                {imageData.map((item, index) => (
                  <div
                    key={index}
                    className={classNames(
                      styles.imageContainer,
                      {
                        [styles.activeImageContainer]: selectedImages.includes(
                          item.originalImageUrl as never,
                        ),
                      },
                      "flex i-align-c position-relative",
                    )}
                    onClick={() => onSelectImage(item.originalImageUrl)}
                  >
                    <img src={item.thumbnailImageUrl} alt="" />
                    <div
                      className={classNames(
                        "position-absolute flex a-items-c",
                        styles.defaultDownloadBtn,
                        {
                          [styles.isDefault]: !selectedImages.includes(
                            item as never,
                          ),
                          [styles.activeImgContainer]: selectedImages.includes(
                            item as never,
                          ),
                        },
                      )}
                    >
                      <DownloadIcon fill={"#F6F6F6"} width={12} height={12} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "gray-btn",
              styles.customBtn,
              styles.mobileCustomBtn,
            )}
            onClick={onPressEndSession}
          >
            Complete Session
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "full-width flex a-items-c",
          styles.desktopContainer,
        )}
      >
        <div className={styles.leftContainer}>
          <div className={styles.textContainer}>
            <p className={styles.header}>Individual headshots</p>
            <p className={styles.subscription}>
              Select a headshot to download. You can also revisit this page at
              any time via the emailed link.
            </p>
          </div>
          <div
            className={classNames(
              "flex justify-content-start",
              styles.btnsContainer,
            )}
          >
            <div
              className={classNames("gray-btn", styles.customBtn)}
              onClick={onPressEndSession}
            >
              Complete Session
            </div>
          </div>
        </div>
        <div className={classNames("t-align-c", styles.rightContainer)}>
          <div className={styles.gridTemplate}>
            {imageData.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.imageContainer,
                  {
                    [styles.activeImageContainer]: selectedImages.includes(
                      item.originalImageUrl as never,
                    ),
                  },
                  "flex i-align-c position-relative",
                )}
                onClick={() => onSelectImage(item.originalImageUrl)}
              >
                <img src={item.thumbnailImageUrl} alt="" />
                <div
                  className={classNames(
                    "position-absolute flex a-items-c",
                    styles.defaultDownloadBtn,
                    {
                      [styles.isDefault]: !selectedImages.includes(
                        item as never,
                      ),
                      [styles.activeImgContainer]: selectedImages.includes(
                        item as never,
                      ),
                    },
                  )}
                >
                  <DownloadIcon fill={"#F6F6F6"} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              styles.footerLeftWrapper,
              "flex a-items-c full-width space-between",
            )}
          >
            <p className="text-white qr-footer-text">
              © 2024. Remote is a <span className="text-gold">Studio Pod</span>{" "}
              Product.
            </p>
            <img src={LogoIcon} alt="" />
          </div>
          <div
            className={classNames(styles.defaultBtnWrapper, styles.btn)}
            onClick={navigateToDownloading}
          >
            <div className={classNames(styles.defaultBtn, styles.goldBtn)}>
              Download Selection
              <img src={NextIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualHeadshotsComponent;
