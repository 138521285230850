import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import classNames from "classnames";
import styles from "../Session/components/HeadshotTipsComponent/HeadshotTipsComponent.module.scss";
import DefaultInput from "../../components/fields/DefaultInput/DefaultInput";
import http from "../../helpers/http";
import InformationModal from "../../components/modals/InformationModal/InformationModal";
import { navigationComponents } from "../../constants/navigation";
import EndSessionPageComponent from "../Session/components/EndSessinComponent/EndSessinComponent";
import { isValidEmail } from "../../utils/utils";
import NextIcon from "../../assets/svg/next-icon.svg";
import LogoIcon from "../../assets/svg/white-union.svg";

const SessionAuthorization = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const studio = searchParams.get("studio");
  const [infoModalIsOpen, toggleInformationModal] = useState(false);
  const [activeComponent, setActiveComponent] = useState("public");
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getSessionType = async () => {
    if (studio) {
      try {
        const res: any = await http.get(
          `/Invite/is-available-create-public-invite/${studio}`,
        );
        if (!res.success) {
          setModalMessage(
            "This studio is private and cannot be accessed without an invitation. Please contact your coordinator for assistance.",
          );
          toggleInformationModal(true);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setModalMessage("Incorrect session link");
      toggleInformationModal(true);
    }
  };

  const onSendInvitation = async () => {
    if (isValidEmail(email)) {
      try {
        setIsLoading(true);
        const res: any = await http.post("/invite/create-public", {
          studioId: studio,
          email,
        });
        if (res.success) {
          setIsLoading(false);
          setModalMessage("Invitation sent successfully");
          toggleInformationModal(true);
        } else {
          setModalMessage(
            "This studio is private and cannot be accessed without an invitation. Please contact your coordinator for assistance.",
          );
          toggleInformationModal(true);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    } else {
      setEmailError(
        "You have entered an invalid email address. Please try again.",
      );
    }
  };

  useEffect(() => {
    getSessionType().catch();
  }, []);

  const handleChangeEmail = (val: string) => {
    if (emailError) {
      setEmailError("");
    }
    setEmail(val);
  };

  const onClickOkBtn = () => {
    toggleInformationModal(false);
    setActiveComponent(navigationComponents.endSession);
  };

  return (
    <Layout activeComponent={activeComponent}>
      {activeComponent === "public" ? (
        <>
          <div
            className={classNames(
              "full-width flex a-items-c flex-column",
              styles.userDataContainer,
              styles.borderRadius,
            )}
          >
            <div>
              <p className="text-gray f-matter f-size39 mb20">Invitation</p>
              <div className="flex flex-column gap24">
                <DefaultInput
                  onChange={handleChangeEmail}
                  label="Email"
                  placeholder={"Email"}
                  inputVal={email}
                  isRequired
                  error={emailError}
                  maxLength={256}
                />
              </div>
            </div>
          </div>
          <div className={classNames(styles.footerWrapper, "flex gap24")}>
            <div
              className={classNames(
                styles.defaultBtnWrapper,
                styles.logoWrapper,
                "flex a-items-c",
              )}
            >
              <img src={LogoIcon} alt="" />
            </div>
            <div className={styles.footerBtnWrapper}>
              <div
                className={classNames(
                  styles.defaultBtn,
                  styles.bgGold,
                  "gap24 cursor-pointer",
                )}
                onClick={() => !isLoading && onSendInvitation()}
              >
                Send Invite
                <img src={NextIcon} alt="" />
              </div>
            </div>
          </div>
          <InformationModal
            modalIsOpen={infoModalIsOpen}
            setIsOpen={onClickOkBtn}
            message={modalMessage}
          />
        </>
      ) : (
        <EndSessionPageComponent />
      )}
    </Layout>
  );
};

export default SessionAuthorization;
