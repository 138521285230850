import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./ConfirmSubmissionComponent.module.scss";
import NextIcon from "assets/svg/next-icon.svg";
import "browser-fs-access";
import LogoIcon from "assets/svg/white-union.svg";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "./styles.scss";
import { navigationComponents } from "constants/navigation";
import { getImages } from "helpers/sessionStorageHelpers";

type ConfirmSubmissionComponentProps = {
  setActiveComponent: (val: string) => void;
  setLoading: (val: boolean) => void;
};
const ConfirmSubmissionComponent: FC<ConfirmSubmissionComponentProps> = ({
  setActiveComponent,
  setLoading,
}) => {
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const [imageData, setImageData] = useState<any[]>([]);

  useEffect(() => {
    const jsonImages = getImages();
    if (jsonImages) {
      const dataObj = JSON.parse(jsonImages);

      const images = [...dataObj];
      for (const image of images) {
        const imageElement = new Image();
        imageElement.src = image;
      }
      setImageData(images);
    }
  }, []);

  const handleImageLoad = () => {
    setLoadedImagesCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    setLoading(true);
    if (loadedImagesCount === imageData.length) {
      setLoading(false);
    }
  }, [loadedImagesCount]);

  const plugins = [
    new Pagination({
      type: "bullet",
    }),
  ];

  // console.log("pre", responsePhotosArray.length);

  const onSubmit = () => {
    setActiveComponent(navigationComponents.individualHeadshots);
  };

  return (
    <div
      className={classNames(
        "position-relative full-width full-height",
        styles.mainContainer,
      )}
    >
      <div
        className={classNames(
          "full-width flex a-items-c flex-column",
          styles.container,
          styles.borderRadius,
        )}
      >
        <div className="flex position-relative a-items-c">
          <Flicking
            plugins={plugins}
            horizontal={true}
            onReady={(e) => console.log("eee", e)}
          >
            {imageData.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    "overflow-hidden position-relative",
                    styles.photoContainer,
                  )}
                >
                  <img
                    src={item.thumbnailImageUrl}
                    alt=""
                    width={"100%"}
                    onLoad={handleImageLoad}
                  />
                </div>
              );
            })}
            <ViewportSlot>
              <div className="flicking-pagination" />
            </ViewportSlot>
          </Flicking>
        </div>
        <h5 className="mt40">Confirm Submission</h5>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              styles.footerLeftWrapper,
              "flex a-items-c space-between",
            )}
          >
            <p className="text-white qr-footer-text">
              © 2024. Remote is a <span className="text-gold">Studio Pod</span>{" "}
              Product.
            </p>
            <img src={LogoIcon} alt="" />
          </div>
          <div
            className={classNames(styles.defaultBtnWrapper, styles.btn)}
            onClick={onSubmit}
          >
            <div className={classNames(styles.defaultBtn, styles.bgGold)}>
              Submit
              <img src={NextIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSubmissionComponent;
