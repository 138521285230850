import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./styles.scss";
import StarIcon from "assets/svg/star.svg";

Modal.setAppElement("#root");

const DefaultModal = ({
  modalIsOpen,
  setIsOpen,
  navigateToQRCode,
}: {
  modalIsOpen: boolean;
  setIsOpen: (val: boolean) => void;
  navigateToQRCode: () => void;
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const customStyles = {
    content: {
      backgroundColor: "#F0F0F0",
      borderRadius: 24,
      border: "none",
      top: "50%",
      left: "50%",
      right: "50%",
      bottom: "auto",
      marginRight: windowWidth >= 1024 ? "-70%" : "-90%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(1, 1, 1, 0.85)",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="flex flex-column modal-content-gap modal-content-container position-relative">
        <div style={{ position: "absolute", top: 10, right: 10 }}>
          <img src={StarIcon} alt="" />
        </div>
        <h4>Notice</h4>
        <div>
          <p className="modal-text">
            By using this product you accept our Terms of Use and Privacy
            Policy.
          </p>
          <p className="modal-text">
            We will never sell or share photos taken on this platform.
            <br />
            <br />
            You can retake your headshot as many times as you want prior to
            final submission. Once you click the &lsquo;SUBMIT&rsquo; button at
            the end, your headshot will be finalized.
          </p>
        </div>
        <div>
          <div className="flex justify-content-start modal-btns-container">
            <div className="custom-modal-btn light-color" onClick={closeModal}>
              Close
            </div>
            <div
              className="custom-modal-btn dark-color"
              onClick={navigateToQRCode}
            >
              I agree
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DefaultModal;
