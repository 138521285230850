import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import classNames from "classnames";
import styles from "./ExamplesComponent.module.scss";
import BackIcon from "assets/svg/back-icon.svg";
import LogoIcon from "assets/svg/white-union.svg";
import NextIcon from "assets/svg/next-icon.svg";
import { navigationComponents } from "constants/navigation";
import "./styles.scss";
import { getStudioData } from "helpers/sessionStorageHelpers";

type ExamplesComponentProps = {
  setActiveComponent: (val: string) => void;
};

const ExamplesComponent: FC<ExamplesComponentProps> = ({
  setActiveComponent,
}) => {
  const [exampleId, setExampleId] = useState(0);
  const [poseId, setPoseId] = useState(1);
  const [poseName, setPoseName] = useState("");

  useLayoutEffect(() => {
    const studioData = getStudioData();
    if (studioData) {
      const dataObj = JSON.parse(studioData);
      setPoseId(dataObj.photoConfiguration.poseProfile.id);
      setPoseName(dataObj.photoConfiguration.poseProfile.name);
    }
  }, []);
  const navigateToNextExample = () => {
    if (exampleId === 0) {
      setExampleId(1);
      activateBlock();
    } else if (exampleId === 1) {
      setExampleId(2);
      activateBlock();
    } else if (exampleId === 2) {
      setActiveComponent(navigationComponents.ready);
    }
  };

  const navigateToPrevExample = () => {
    if (exampleId === 0) {
      setActiveComponent(navigationComponents.guidelines);
    } else if (exampleId === 1) {
      setExampleId(0);
      activateBlock();
    } else if (exampleId === 2) {
      setExampleId(1);
      activateBlock();
    }
  };

  const [isAnimated, setAnimated] = useState(false);

  useEffect(() => {
    if (isAnimated) {
      const blocks = document.querySelectorAll(".block");
      blocks.forEach((block) => block.classList.remove("active"));

      const activeBlock = document.querySelector(`.block${exampleId + 1}`);
      if (activeBlock) activeBlock.classList.add("active");
      setAnimated(false);
    }
  }, [exampleId, isAnimated]);
  function activateBlock() {
    setAnimated(true);
  }

  return (
    <>
      <div
        className={classNames("full-width flex a-items-c", styles.container)}
      >
        <div
          className={classNames(
            "block block1 active",
            styles.borderRadius,
            exampleId === 0 && ["bgExample1 animated", styles.fullBlock],
            exampleId !== 0 && [
              styles.defaultBg,
              styles.displayBlock,
              styles.smallBlock,
            ],
          )}
          onClick={() => {
            activateBlock();
            setExampleId(0);
          }}
        >
          {poseId === 2 && exampleId === 0 && (
            <img
              src={require("assets/images/examples/ShoulderHeight/shoulder_height_example1.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 1 && exampleId === 0 && (
            <img
              src={require("assets/images/examples/NoOutline/no_outline_example1.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 3 && exampleId === 0 && (
            <img
              src={require("assets/images/examples/ShoulderHeightClose/shoulder_height_close_example1.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 4 && exampleId === 0 && (
            <img
              src={require("assets/images/examples/ShoulderHeightCustomExamples/shoulder_height_custom_examples_example1.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 5 && exampleId === 0 && (
            <img
              src={require("assets/images/examples/WaistHeight/waist_height_example1.jpg")}
              alt="Example Image"
            />
          )}
          {exampleId === 0 ? (
            <>
              <div className={styles.headerText}>Examples</div>
              <div className={styles.blurContainer}>
                <p className={styles.blurTitle}>{poseName} headshot</p>
              </div>
            </>
          ) : (
            <div className={styles.textContainer}>
              <p className={styles.header}>example 1</p>
              <p className={styles.title}>
                {poseName}
                <br /> headshot
              </p>
            </div>
          )}
        </div>
        <div
          className={classNames(
            "t-align-c block block2",
            styles.borderRadius,
            exampleId === 1 && ["bgExample2 animated", styles.fullBlock],
            exampleId !== 1 && [
              styles.defaultBg,
              styles.displayBlock,
              styles.smallBlock,
            ],
          )}
          onClick={() => {
            activateBlock();
            setExampleId(1);
          }}
        >
          {poseId === 2 && exampleId === 1 && (
            <img
              src={require("assets/images/examples/ShoulderHeight/shoulder_height_example2.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 1 && exampleId === 1 && (
            <img
              src={require("assets/images/examples/NoOutline/no_outline_example2.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 3 && exampleId === 1 && (
            <img
              src={require("assets/images/examples/ShoulderHeightClose/shoulder_height_close_example2.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 4 && exampleId === 1 && (
            <img
              src={require("assets/images/examples/ShoulderHeightCustomExamples/shoulder_height_custom_examples_example2.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 5 && exampleId === 1 && (
            <img
              src={require("assets/images/examples/WaistHeight/waist_height_example2.jpg")}
              alt="Example Image"
            />
          )}
          {exampleId === 1 ? (
            <>
              <div className={styles.headerText}>Examples</div>
              <div className={styles.blurContainer}>
                <p className={styles.blurTitle}>{poseName} headshot</p>
              </div>
            </>
          ) : (
            <div className={styles.textContainer}>
              <p className={styles.header}>example 2</p>
              <p className={styles.title}>
                {poseName} <br /> headshot
              </p>
            </div>
          )}
        </div>
        <div
          className={classNames(
            "t-align-c block block3",
            styles.borderRadius,
            exampleId === 2 && ["bgExample3 animated", styles.fullBlock],
            exampleId !== 2 && [
              styles.defaultRightBg,
              styles.displayBlock,
              styles.smallBlock,
            ],
          )}
          onClick={() => {
            activateBlock();
            setExampleId(2);
          }}
        >
          {poseId === 2 && exampleId === 2 && (
            <img
              src={require("assets/images/examples/ShoulderHeight/shoulder_height_example3.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 1 && exampleId === 2 && (
            <img
              src={require("assets/images/examples/NoOutline/no_outline_example3.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 3 && exampleId === 2 && (
            <img
              src={require("assets/images/examples/ShoulderHeightClose/shoulder_height_close_example3.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 4 && exampleId === 2 && (
            <img
              src={require("assets/images/examples/ShoulderHeightCustomExamples/shoulder_height_custom_examples_example3.jpg")}
              alt="Example Image"
            />
          )}
          {poseId === 5 && exampleId === 2 && (
            <img
              src={require("assets/images/examples/WaistHeight/waist_height_example3.jpg")}
              alt="Example Image"
            />
          )}
          {exampleId === 2 ? (
            <>
              <div className={styles.headerText}>Examples</div>
              <div className={styles.blurContainer}>
                <p className={styles.blurTitle}>{poseName} headshot</p>
              </div>
            </>
          ) : (
            <div className={styles.textContainer}>
              <p className={styles.header}>example 3</p>
              <p className={styles.title}>
                {poseName}
                <br /> headshot
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div className={classNames(styles.defaultBtnWrapper)}>
            <div
              onClick={navigateToPrevExample}
              className={classNames(styles.defaultBtn, styles.bgGray)}
            >
              <img src={BackIcon} alt="" />
              Back
            </div>
          </div>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              "flex a-items-c",
            )}
          >
            <img src={LogoIcon} alt="" />
          </div>
          <div
            className={classNames(styles.defaultBtnWrapper)}
            onClick={navigateToNextExample}
          >
            <div className={classNames(styles.defaultBtn, styles.bgGold)}>
              Next Example
              <img src={NextIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamplesComponent;
