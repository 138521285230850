import React from "react";
import QRCode from "qrcode.react";

const QrCodeGenerator = ({ link }: { link: string }) => {
  return (
    <QRCode
      value={link} // Replace with your dynamic link
      size={256} // Adjust size as needed
      level="H" // Error correction level (optional)
    />
  );
};

export default QrCodeGenerator;
