import React from "react";

const ZoomIcon = ({ fill }: { fill: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="edit / search">
      <path
        id="coolicon"
        d="M18.677 19.8212L12.962 14.1052C10.4196 15.9127 6.91642 15.4705 4.90285 13.0881C2.88929 10.7057 3.03714 7.17778 5.24298 4.97219C7.44824 2.76564 10.9765 2.61715 13.3594 4.63061C15.7422 6.64407 16.1846 10.1476 14.377 12.6902L20.092 18.4062L18.678 19.8202L18.677 19.8212ZM9.48498 5.21418C7.58868 5.21375 5.95267 6.54487 5.56745 8.40163C5.18224 10.2584 6.15369 12.1305 7.89366 12.8844C9.63362 13.6384 11.6639 13.067 12.7552 11.5162C13.8466 9.96547 13.699 7.86151 12.402 6.47819L13.007 7.07819L12.325 6.39819L12.313 6.38619C11.5648 5.63337 10.5464 5.21132 9.48498 5.21418Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default ZoomIcon;
