import React from "react";
import styles from "./EndSessinComponent.module.scss";
import classNames from "classnames";
import LogoIcon from "assets/svg/white-union.svg";
import TextIcon from "assets/svg/short-text.svg";

const EndSessionPageComponent = () => {
  return (
    <>
      <div
        className={classNames(
          "full-width flex a-items-c flex-column position-relative",
          styles.container,
          styles.borderRadius,
        )}
      >
        <div className={styles.logoWrapper}>
          <img src={LogoIcon} alt="" />
        </div>
        <div className={styles.textWrapper}>
          <img src={TextIcon} alt="" />
        </div>
      </div>
    </>
  );
};

export default EndSessionPageComponent;
