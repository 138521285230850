import React, { FC, useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { navigationComponents } from "constants/navigation";
import { getUserData, setUserData } from "helpers/sessionStorageHelpers";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import styles from "../HeadshotTipsComponent/HeadshotTipsComponent.module.scss";
import classNames from "classnames";
import BackIcon from "assets/svg/back-icon.svg";
import LogoIcon from "assets/svg/white-union.svg";
import NextIcon from "assets/svg/next-icon.svg";
import validator from "validator";

type UserDataComponentProps = {
  setActiveComponent: (val: string) => void;
};
const UserDataComponent: FC<UserDataComponentProps> = ({
  setActiveComponent,
}) => {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [id, setId] = useState(0);
  const [isReadOnlyName, setReadOnlyName] = useState(false);
  const [isReadOnlyLastName, setReadOnlyLastName] = useState(false);
  const [isReadOnlyPhone, setReadOnlyPhone] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    const userData = getUserData();
    if (userData) {
      const dataObj = JSON.parse(userData);
      if (dataObj.firstName) {
        setFirstName(dataObj.firstName);
        setReadOnlyName(true);
      }
      if (dataObj.lastName) {
        setLastName(dataObj.lastName);
        setReadOnlyLastName(true);
      }
      if (dataObj.phone) {
        setReadOnlyPhone(true);
        setPhone(dataObj.phone);
      }
      setEmail(dataObj.email);
      setId(dataObj.id);
    }
  }, []);

  const navigateToQRCode = () => {
    const navigateTo = isMobileOnly
      ? navigationComponents.headshotTips
      : navigationComponents.qrCode;
    if (!!firstName && !!lastName) {
      setUserData(
        JSON.stringify({
          id,
          firstName,
          lastName,
          email,
          phone,
        }),
      );
      setActiveComponent(navigateTo);
    } else {
      if (!firstName) {
        setFirstNameError("First name is required");
      }
      if (!lastName) {
        setLastNameError("Last name is required");
      }
    }
  };

  const navigateToIntro = () => {
    setActiveComponent(navigationComponents.intro);
  };

  const onChangeFirstName = (val: string) => {
    if (firstNameError) setFirstNameError("");
    setFirstName(val);
  };

  const onChangeLastName = (val: string) => {
    if (lastNameError) setLastNameError("");
    setLastName(val);
  };
  const onChangePhone = (val: string) => {
    setPhone(val);
    if (!val.length) {
      setPhoneError("");
    } else if (validator.isMobilePhone(val)) {
      // if(checkPhone())
      setPhoneError("");
    } else {
      setPhoneError("Enter your valid Phone Number!");
    }
  };

  return (
    <>
      <div
        className={classNames(
          "full-width flex a-items-c flex-column",
          styles.userDataContainer,
          styles.borderRadius,
        )}
      >
        <div>
          <p className="text-gray f-matter f-size39">Tell us about yourself</p>
          <p className="f-matter text-gray2 f-size24 mt12 mb12">{email}</p>
          <div className="flex flex-column gap24">
            <DefaultInput
              onChange={onChangeFirstName}
              label="First name"
              inputVal={firstName}
              isRequired
              error={firstNameError}
              maxLength={100}
              isReadonly={isReadOnlyName}
            />
            <DefaultInput
              onChange={onChangeLastName}
              label="Last name"
              inputVal={lastName}
              isRequired
              error={lastNameError}
              maxLength={100}
              isReadonly={isReadOnlyLastName}
            />
            <DefaultInput
              onChange={onChangePhone}
              label="Phone"
              inputVal={phone}
              maxLength={24}
              isReadonly={isReadOnlyPhone}
              error={phoneError}
            />
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div className={classNames(styles.defaultBtnWrapper)}>
            <div
              onClick={navigateToIntro}
              className={classNames(styles.defaultBtn, styles.bgGray)}
            >
              <img src={BackIcon} alt="" />
              Back
            </div>
          </div>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              "flex a-items-c",
            )}
          >
            <img src={LogoIcon} alt="" />
          </div>
          <div
            className={classNames(styles.defaultBtnWrapper)}
            onClick={navigateToQRCode}
          >
            <div className={classNames(styles.defaultBtn, styles.bgGold)}>
              Continue
              <img src={NextIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDataComponent;
