import React, { FC } from "react";
import classNames from "classnames";
import styles from "../HeadshotTipsComponent/HeadshotTipsComponent.module.scss";
import BackIcon from "assets/svg/back-icon.svg";
import LogoIcon from "assets/svg/white-union.svg";
import NextIcon from "assets/svg/next-icon.svg";
import { navigationComponents } from "constants/navigation";
import styles2 from "./GuidelinesComponent.module.scss";
import ArrowGold from "assets/svg/arrow-gold.svg";

type GuidelinesComponentProps = {
  setActiveComponent: (val: string) => void;
};
const GuidelinesComponent: FC<GuidelinesComponentProps> = ({
  setActiveComponent,
}) => {
  const navigateToExamples = () =>
    setActiveComponent(navigationComponents.examples);

  const navigateToHeadshots = () =>
    setActiveComponent(navigationComponents.headshotTips);

  return (
    <>
      <div
        className={classNames(
          "full-width flex a-items-c flex-column",
          styles.userDataContainer,
          styles.borderRadius,
        )}
      >
        <div
          className="flex flex-column a-items-l full-height space-between"
          style={{ paddingTop: "10%", paddingBottom: "10%" }}
        >
          <p className={styles2.header}>Guidelines</p>
          <div className="flex gap24 flex-column a-items-l">
            <div className="flex gap24">
              <img src={ArrowGold} alt="" />
              <p className={styles2.subscription}>
                Wear professional attire - Button ups, dresses, blazers, and
                blouses encouraged
              </p>
            </div>
            <div className="flex gap24">
              <img src={ArrowGold} alt="" />
              <p className={styles2.subscription}>
                Posture is key! Keep your back straight and avoid slouching or
                hunching your shoulders
              </p>
            </div>
            <div className="flex gap24">
              <img src={ArrowGold} alt="" />
              <p className={styles2.subscription}>
                Focus your eyes on the camera lens
              </p>
            </div>
            <div className="flex gap24">
              <img src={ArrowGold} alt="" />
              <p className={styles2.subscription}>
                Take a deep breath, relax, and smile
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div className={classNames(styles.defaultBtnWrapper)}>
            <div
              onClick={navigateToHeadshots}
              className={classNames(styles.defaultBtn, styles.bgGray)}
            >
              <img src={BackIcon} alt="" />
              Back
            </div>
          </div>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              "flex a-items-c",
            )}
          >
            <img src={LogoIcon} alt="" />
          </div>
          <div
            className={classNames(styles.defaultBtnWrapper)}
            onClick={navigateToExamples}
          >
            <div className={classNames(styles.defaultBtn, styles.bgGold)}>
              Continue
              <img src={NextIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GuidelinesComponent;
