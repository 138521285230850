import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./HeadshotTipsComponent.module.scss";
import Flicking from "@egjs/react-flicking";
import BackIcon from "assets/svg/back-icon.svg";
import LogoIcon from "assets/svg/white-union.svg";
import NextIcon from "assets/svg/next-icon.svg";
import { navigationComponents } from "constants/navigation";
import { isMobileOnly } from "react-device-detect";
import "./styles.scss";

type HeadshotTipsComponentProps = {
  setActiveComponent: (val: string) => void;
};
const HeadshotTipsComponent: FC<HeadshotTipsComponentProps> = ({
  setActiveComponent,
}) => {
  const [tipId, setTipId] = useState(0);
  const [isOpenSecondTipDetails, openSecondTipDetails] = useState(false);
  const [isOpenFirstTipDetails, openFirstTipDetails] = useState(false);
  const [isOpenThirdTipDetails, openThirdTipDetails] = useState(false);

  const navigateToNextTip = () => {
    if (tipId === 0) {
      setTipId(1);
      activateBlock();
    } else if (tipId === 1) {
      setTipId(2);
      activateBlock();
    } else {
      setActiveComponent(navigationComponents.guidelines);
    }
  };

  const navigateToPrevTip = () => {
    if (tipId === 0) {
      const navigateTo = isMobileOnly
        ? navigationComponents.intro
        : navigationComponents.qrCode;
      setActiveComponent(navigateTo);
    } else if (tipId === 1) {
      setTipId(0);
      activateBlock();
    } else {
      setTipId(1);
      activateBlock();
    }
  };

  const onClickSecondTipMoreBtn = () => {
    openSecondTipDetails(true);
  };

  const onClickSecondTipCloseBtn = () => {
    openSecondTipDetails(false);
  };

  const onClickFirstTipMoreBtn = () => {
    openFirstTipDetails(true);
  };

  const onClickFirstTipCloseBtn = () => {
    openFirstTipDetails(false);
  };

  const onClickThirdTipMoreBtn = () => {
    openThirdTipDetails(true);
  };

  const onClickThirdTipCloseBtn = () => {
    openThirdTipDetails(false);
  };

  const [isAnimated, setAnimated] = useState(false);

  useEffect(() => {
    if (isAnimated) {
      const blocks = document.querySelectorAll(".block");
      blocks.forEach((block) => block.classList.remove("active"));

      const activeBlock = document.querySelector(`.block${tipId + 1}`);
      if (activeBlock) activeBlock.classList.add("active");
      setAnimated(false);
    }
  }, [isAnimated, tipId]);
  function activateBlock() {
    setAnimated(true);
  }

  return (
    <>
      <div
        className={classNames("full-width flex a-items-c", styles.container)}
      >
        <div
          className={classNames(
            "block block1 active",
            styles.borderRadius,
            tipId === 0 && ["bgTip1 animated", styles.fullBlock],
            tipId !== 0 && [
              styles.defaultBg,
              styles.displayBlock,
              styles.smallBlock,
            ],
          )}
          onClick={() => {
            activateBlock();
            setTipId(0);
          }}
        >
          {tipId === 0 ? (
            <>
              <img
                src={require("assets/images/firsttips.png")}
                alt="Example Image"
              />
              <div className={styles.headerText}>Headshot Tips</div>
              <div className={styles.blurContainer}>
                <p className={styles.blurTitle}>Find a natural light source</p>
                <p className={styles.text}>
                  Make sure to use a good, natural light source. Try facing a
                  window without direct sunlight.
                </p>
                {isOpenFirstTipDetails && (
                  <div id="moreTextContainer">
                    <p className={styles.textSmall}>
                      Lighting is the biggest factor in taking a great headshot.
                      Try facing a window or open area. Avoid direct sunlight on
                      your face. You can also try using a shady spot outside or
                      a well-lit room.
                    </p>
                    <p className={styles.text}>
                      Pro tip: indirect sunlight gives a nice softening effect
                      and produces the best results!
                    </p>
                    <p className={styles.sliderHeader}>
                      Ideal lighting examples:
                    </p>
                    <div className="flex">
                      <Flicking
                        defaultIndex={0}
                        autoResize={true}
                        align={{ camera: "0", panel: "0" }}
                      >
                        <div style={{ width: 100, height: 100 }}>
                          <img
                            src={require("assets/images/lighting-example1.png")}
                            alt=""
                            width={100}
                            height={100}
                          />
                        </div>
                        <div style={{ width: 100, height: 100 }}>
                          <img
                            src={require("assets/images/lighting-example 2.png")}
                            alt=""
                            width={100}
                            height={100}
                          />
                        </div>
                        <div style={{ width: 100, height: 100 }}>
                          <img
                            src={require("assets/images/lighting-example1.png")}
                            alt=""
                            width={100}
                            height={100}
                          />
                        </div>
                        <div style={{ width: 100, height: 100 }}>
                          <img
                            src={require("assets/images/lighting-example 2.png")}
                            alt=""
                            width={100}
                            height={100}
                          />
                        </div>
                      </Flicking>
                    </div>
                  </div>
                )}
                <div
                  className={styles.btn}
                  onClick={
                    isOpenFirstTipDetails
                      ? onClickFirstTipCloseBtn
                      : onClickFirstTipMoreBtn
                  }
                >
                  {isOpenFirstTipDetails ? "Close" : "Learn more"}
                </div>
              </div>
            </>
          ) : (
            <div className={styles.textContainer}>
              <p className={styles.header}>Headshot Tips</p>
              <p className={styles.title}>Find a natural light source</p>
            </div>
          )}
        </div>
        <div
          className={classNames(
            "t-align-c block block2",
            styles.borderRadius,
            tipId === 1 && ["bgTip2 animated", styles.fullBlock],
            tipId !== 1 && [
              styles.defaultBg,
              styles.displayBlock,
              styles.smallBlock,
            ],
          )}
          onClick={() => {
            activateBlock();
            setTipId(1);
          }}
        >
          {tipId === 1 ? (
            <>
              <img
                src={require("assets/images/secondtips.png")}
                alt="Example Image"
              />
              <div className={styles.headerText}>Headshot Tips</div>
              <div className={styles.blurContainer}>
                <p className={classNames(styles.blurTitle, "t-align-l")}>
                  Hold phone above eye level
                </p>
                <p className={classNames(styles.text, "t-align-l")}>
                  Make sure the phone lens is positioned slightly above eye
                  level.
                </p>
                {isOpenSecondTipDetails && (
                  <div id="moreTextContainer" className="t-align-l">
                    <p className={styles.textSmall}>
                      For best results and a natural look, capture your headshot
                      slightly above eye level. If you have someone else taking
                      your photo and they happen to be shorter than you,
                      don&apos;t worry, sitting on a stool will fix the problem
                      as most headshots are shoulders up.
                    </p>
                    <p className={styles.text}>
                      Pro Tip: Make sure to focus the camera on the
                      subject&apos;s eyes!
                    </p>
                  </div>
                )}
                <div
                  className={styles.btn}
                  onClick={
                    isOpenSecondTipDetails
                      ? onClickSecondTipCloseBtn
                      : onClickSecondTipMoreBtn
                  }
                >
                  {isOpenSecondTipDetails ? "Close" : "Learn more"}
                </div>
              </div>
            </>
          ) : (
            <div className={styles.textContainer}>
              <p className={styles.header}>Headshot Tips</p>
              <p className={styles.title}>Hold phone above eye level</p>
            </div>
          )}
        </div>
        <div
          className={classNames(
            "t-align-c block block3",
            styles.borderRadius,
            tipId === 2 && ["bgTip3 animated", styles.fullBlock],
            tipId !== 2 && [
              styles.defaultRightBg,
              styles.displayBlock,
              styles.smallBlock,
            ],
          )}
          onClick={() => {
            activateBlock();
            setTipId(2);
          }}
        >
          {tipId === 2 ? (
            <>
              <img
                src={require("assets/images/thirdtips.png")}
                alt="Example Image"
              />
              <div className={styles.headerText}>Headshot Tips</div>
              <div className={classNames(styles.blurContainer, "t-align-l")}>
                <p className={styles.blurTitle}>Try different angles</p>
                <p className={styles.text}>
                  Turn your body to a slight angle when taking a photo. Try
                  various poses until you find a winner!
                </p>
                {isOpenThirdTipDetails && (
                  <div id="moreTextContainer" className="t-align-l">
                    <p className={styles.textSmall}>
                      Experiment with various head tilts, shoulder positions,
                      and even different smiles! You want to give your headshot
                      a little more depth and working the angles will help.
                    </p>
                    <p className={styles.text}>
                      Pro Tip: Lean your head towards the camera ever so
                      slightly. You&apos;ll bring more focus to your face and
                      avoid unflattering angles.
                    </p>
                  </div>
                )}
                <div
                  className={styles.btn}
                  onClick={
                    isOpenThirdTipDetails
                      ? onClickThirdTipCloseBtn
                      : onClickThirdTipMoreBtn
                  }
                >
                  {isOpenThirdTipDetails ? "Close" : "Learn more"}
                </div>{" "}
              </div>
            </>
          ) : (
            <div className={styles.textContainer}>
              <p className={styles.header}>Headshot Tips</p>
              <p className={styles.title}>Try different angles</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footerBtnWrapper}>
          <div className={classNames(styles.defaultBtnWrapper)}>
            <div
              onClick={navigateToPrevTip}
              className={classNames(styles.defaultBtn, styles.bgGray)}
            >
              <img src={BackIcon} alt="" />
              Back
            </div>
          </div>
          <div
            className={classNames(
              styles.defaultBtnWrapper,
              styles.logoWrapper,
              "flex a-items-c",
            )}
          >
            <img src={LogoIcon} alt="" />
          </div>
          <div
            className={classNames(styles.defaultBtnWrapper)}
            onClick={navigateToNextTip}
          >
            <div className={classNames(styles.defaultBtn, styles.bgGold)}>
              Next Tip
              <img src={NextIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadshotTipsComponent;
