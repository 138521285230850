import React, { FC } from "react";
import "./styles.scss";
import classNames from "classnames";
import Union from "assets/svg/union.svg";

type FooterProps = {
  customStyles?: string;
};

const Footer: FC<FooterProps> = ({ customStyles }) => {
  const footerClasses = classNames(
    customStyles,
    "default-border qr-footer-container flex a-items-c space-between",
  );
  return (
    <div className={footerClasses}>
      <p className="text-white qr-footer-text">
        © 2024. Remote is a <span className="text-gold">Studio Pod</span>{" "}
        Product.
      </p>
      <img src={Union} alt="" />
    </div>
  );
};

export default Footer;
