import React, { FC, useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./RemovingBgComponent.module.scss";
import NextIcon from "assets/svg/next-icon.svg";
import BackIcon from "assets/svg/back-icon.svg";
import "browser-fs-access";
import { useAppDispatch, useAppSelector } from "store";
import { setUploadPhoto } from "store/slices/photos";
import LogoIcon from "assets/svg/white-union.svg";
// import ProgressBarComponent from "components/ProgressBarComponent/ProgressBarComponent";
import http from "helpers/http";
import { navigationComponents } from "constants/navigation";
import {
  getToken,
  getUserData,
  setImages,
} from "helpers/sessionStorageHelpers";
import axios from "axios";

type RemovingBgComponentProps = {
  setActiveComponent: (val: string) => void;
  setLoading: (val: boolean) => void;
};
const RemovingBgComponent: FC<RemovingBgComponentProps> = ({
  setActiveComponent,
  setLoading,
}) => {
  const dispatch = useAppDispatch();

  const { uploadPhoto } = useAppSelector((state) => state.photos);

  const imageRef = useRef(null);

  const goToReadyPage = () => {
    dispatch(setUploadPhoto({ uploadPhoto: "", photoFrom: "" }));
    setActiveComponent(navigationComponents.ready);
  };

  const goToConfirmSubmission = () => {
    setActiveComponent(navigationComponents.confirmSubmission);
  };

  const onUploadImage = async () => {
    setLoading(true);
    const userData = getUserData();
    if (userData) {
      const token = getToken();
      const dataObj = JSON.parse(userData);
      const headers = {
        Authorization: `Bearer ${token}`, // Replace with your authorization token if needed
        "Content-Type": "application/json",
      };

      const res: any = await axios.post(
        // "https://vitnif-001-site8.ftempurl.com/api/session/studio-processing-image",
        "https://studiopodapi.com/api/session/studio-processing-image",
        {
          imageBase64: uploadPhoto,
          contact: {
            id: dataObj.id,
            firstName: dataObj.firstName,
            lastName: dataObj.lastName,
            email: dataObj.email,
            phone: dataObj.phone ? dataObj.phone : null,
          },
        },
        {
          headers,
        },
      );
      if (res.data.success) {
        const executeSecondRequest = async () => {
          const res2: any = await http.post(
            "/session/get-result-studio-processing-image",
            { publicId: res.data.data.publicId },
            { headers: { Authorization: `Bearer ${token}` } },
          );
          if (!res2.success) {
            setTimeout(executeSecondRequest, 2000);
          } else {
            setImages(JSON.stringify(res2.data));
            setActiveComponent(navigationComponents.confirmSubmission);
            setLoading(false);
          }
        };
        setTimeout(executeSecondRequest, 2000);
      }
    }
  };

  useEffect(() => {
    onUploadImage().catch();
  }, []);

  return (
    <>
      <div
        className={classNames(
          "app-padding full-width full-height",
          styles.mainContainer,
        )}
      >
        <div
          className={classNames(
            "full-width flex a-items-c flex-column",
            styles.container,
            styles.borderRadius,
          )}
        >
          <div
            className={classNames(
              "overflow-hidden position-relative flex a-items-c",
              styles.photoContainer,
            )}
          >
            <img src={uploadPhoto} alt="" ref={imageRef} width={"100%"} />
            <div className={styles.photoContainerAbsolute}>
              <img src={LogoIcon} alt="" width={120} />
            </div>
          </div>
          {/*<ProgressBarComponent completed={progress} />*/}
          <h5>Removing Background</h5>
        </div>
        <div className={styles.footerWrapper}>
          <div className={styles.footerBtnWrapper}>
            <div className={classNames(styles.defaultBtnWrapper)}>
              <div
                onClick={goToReadyPage}
                className={classNames(styles.defaultBtn, styles.bgGray)}
              >
                <img src={BackIcon} alt="" />
                Back
              </div>
            </div>
            <div
              className={classNames(
                styles.defaultBtnWrapper,
                styles.logoWrapper,
                "flex a-items-c",
              )}
            >
              <img src={LogoIcon} alt="" />
            </div>
            <div className={classNames(styles.defaultBtnWrapper)}>
              <div
                className={classNames(styles.defaultBtn, styles.bgGold)}
                onClick={goToConfirmSubmission}
                // onClick={onUploadImage}
              >
                Continue
                <img src={NextIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemovingBgComponent;
