import React from "react";

const DownloadIcon = ({
  fill = "#F6F6F6",
  width = 24,
  height = 24,
}: {
  fill: string;
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="basic / download">
      <path
        id="coolicon"
        d="M19 22H5V20H19V22ZM12 18L6 12L7.41 10.59L11 14.17V2H13V14.17L16.59 10.59L18 12L12 18Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default DownloadIcon;
