import axios, { AxiosRequestConfig } from "axios";
export interface IRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
  _disableErrorHandler?: boolean;
}

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  "Access-Control-Allow-Headers":
    "x-requested-with,Content-Type,origin,authorization,accept,client-sent-security-token",
};

export const apiInstance = axios.create({
  baseURL: "https://studiopodapi.com/api",
  // baseURL: "https://vitnif-001-site8.ftempurl.com/api",
  headers,
});

apiInstance.interceptors.request.use(
  (config) => {
    config.headers = config.headers ?? {};
    return config;
  },
  (error) => Promise.reject(error),
);

// eslint-disable-next-line no-async-promise-executor
const makeHttpRequest = (apiCall: any) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const response = await apiCall();
      resolve(response.data);
    } catch (e: any) {
      if (e.response.status == 400) {
        resolve(e.response.data);
      } else {
        reject(e.response?.data);
      }
    }
  });

export const http = {
  get: (url: string, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.get(url, options)),
  post: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.post(url, data, options)),
  put: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.put(url, data, options)),
  patch: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.patch(url, data, options)),
  delete: (url: string, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.delete(url, options)),
};

export default http;
