import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
} from "redux-persist";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import photosReducer from "./slices/photos";
import storage from "redux-persist/lib/storage";

const persistPhotosConfig = {
  key: "photos",
  storage,
};

const persistedPhotosReducer = persistReducer(
  persistPhotosConfig,
  photosReducer,
);

const rootReducer = combineReducers({
  photos: persistedPhotosReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const clearPersistor = async () => {
  persistor.purge();
  // store.dispatch(clearUserState());
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
