import React from "react";
import Lottie from "lottie-react";
import loader from "./animation.json";

const LoadingAnimation = () => (
  <div
    style={{ background: "rgba(17,17,17,0.96)", top: 0, left: 0 }}
    className="full-width full-height position-absolute"
  >
    <Lottie
      animationData={loader}
      loop
      style={{
        width: "85%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
      }}
    />
  </div>
);

export default LoadingAnimation;
