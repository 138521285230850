export const navigationComponents = {
  intro: "intro",
  headshotTips: "headshot-tips",
  qrCode: "qr-code",
  examples: "examples",
  ready: "ready",
  takePhoto: "take-photo",
  adjustYourPhoto: "adjust-your-photo",
  removingBackground: "removing-background",
  confirmSubmission: "confirm-submission",
  individualHeadshots: "individual-headshots",
  endSession: "end-session",
  userData: "user-data",
  guidelines: "guidelines",
};
