import { createSlice } from "@reduxjs/toolkit";
import { FacingMode } from "react-camera-pro/dist/components/Camera/types";

type PhotosState = {
  uploadPhoto: string;
  photoFrom: "upload" | "camera";
  firstPhoto: string;
  secondPhoto: string;
  thirdPhoto: string;
  responsePhotosArray: string[];
  facingMode: FacingMode;
};

const initialState: PhotosState = {
  uploadPhoto: "",
  photoFrom: "upload",
  firstPhoto: "",
  secondPhoto: "",
  thirdPhoto: "",
  responsePhotosArray: [],
  facingMode: "user",
};

export const photosSlice = createSlice({
  name: "photos",
  initialState,
  reducers: {
    setUploadPhoto: (state, action) => {
      state.uploadPhoto = action.payload.uploadPhoto;
      state.photoFrom = action.payload.photoFrom;
      if (action.payload?.facingMode)
        state.facingMode = action.payload.facingMode;
    },
    setRemovedBgResult: (state, action) => {
      state.responsePhotosArray = [...action.payload];
    },
  },
});

export const { setUploadPhoto, setRemovedBgResult } = photosSlice.actions;

export default photosSlice.reducer;
