import React, { FC } from "react";
import "./styles.scss";
import classNames from "classnames";

type FooterProps = {
  customStyles?: string;
};

const Footer: FC<FooterProps> = ({ customStyles }) => {
  const footerClasses = classNames(
    customStyles,
    "default-border footer-container flex a-items-c",
  );
  return (
    <div className={footerClasses}>
      <p className="text-white footer-text">
        © 2024. Remote is a <span className="text-gold">Studio Pod</span>{" "}
        Product.
      </p>
    </div>
  );
};

export default Footer;
